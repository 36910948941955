export interface Authentication {
    jwToken: string,
    refreshToken: string,
    errors: Array<string>,
    httpStatus: number,
    status: number,
    expires: number,
    role: string,
    sub: string
}

export interface Admin {
    registrations: Array<any>
}

export interface NodeInfo {
    id: string,
    alias: string,
    updatedAt: any,
    isOnline: boolean,
    outOfUse: boolean
}

export class NodeInfoFunctions {
    static compareTo(a: NodeInfo, b: NodeInfo): number {
        return a.alias.toLowerCase().localeCompare(b.alias.toLowerCase())
    }

    static sortDesc(nodes: Array<NodeInfo>): Array<NodeInfo> {
        return nodes.sort((a: NodeInfo, b: NodeInfo) => this.compareTo(a, b))
    }

    static sortAsc(nodes: Array<NodeInfo>): Array<NodeInfo> {
        return nodes.sort((a: NodeInfo, b: NodeInfo) => -this.compareTo(a, b))
    }
}
export interface NodeInfoItem extends NodeInfo {
    removeFromComparison: any
}

export interface CalcDataField {
    id: number,
    fieldName: string,
    unitSymbol: string
}

export interface CalcDataFieldValue {
    val: number,
    calcDataFieldName: string
}

export interface NodeData {
    alias: string,
    calcDataFieldValues: Array<CalcDataFieldValue>
}

export interface Data {
    timestamp: Date,
    value: number
}

export interface Unit {
    name: string,
    symbol: string
}

export interface MeterUnit extends Unit {
    meterUnitId: number,
    referenceName: string,
    dataFunc: string,
    graphColorHex: string,
    decimals: number
}

export interface ShownUnit {
    meterUnitId: number,
    data: Array<Data>,
    dataFunc: string,
    graphColorHex: string,
    decimals: number
}

export interface MeterWithMeterUnits {
    name: string,       // Meters.Name
    modelName: string,  // Meters.ModelId = MeterModels.Id --> MeterModels.Name
    displayedMeterUnitsWithData: Array<MeterUnit>,   // Meters.Id = MeterUnits.MeterId AND MeterUnits.IsDisplayed = 1 AND MeterUnits.UnitId = MeterUnitPresets.Id
                                                     // AND MeterUnitPresets.UnitId = Units.Id --> MeterUnitPresets.ReferenceName, Units.Name, Units.Symbol
    unitIds: number[]
}

export interface Meter {
    unitIds: any,
    displayedMeterUnitsWithData: any,
    [x: string]: any,
    id: string,
    nodeId: string,
    modelId: number,
    address: string,
    name: string,
    encryptionKey: string
}

export interface GlobalData {
    cO2_kg_kWh: number,
    cO2_kg_m3_gas: number
}

export interface WarmWaterData {
    mx: number,
    cx: number,
    warmWaterUsage_m3: number,
    coldWaterTemperature_C: number,
    warmWaterTemperature_C: number,
    warmWaterGeneratorGain_percent: number,
    idleLoss_percent: number
}

export interface WarmWaterResult {
    physical_MJ: number,
    withGainAdjustmentCVKetel_MJ: number,
    withGainAdjustmentCVKetelAndIdleLoss_MJ: number,
    physical_kWh: number,
    withGainAdjustmentCVKetel_kWh: number,
    withGainAdjustmentCVKetelAndIdleLoss_kWh: number,
    gasPhysicalFromMJ_m3: number,
    gasPhysicalFromkWh_m3: number,
    gasFromMJWithCVGain_m3: number,
    gasFromkWhWithCVGain_m3: number,
    gasFromMJWithAllLosses_m3: number,
    gasFromkWhWithAllLosses_m3: number
}

export interface CookingData {
    cookingGasPerYear_m3: number
}

export interface CookingResult {
    cookingGasPerDay_m3: number,
    cookingGasThroughPeriod_m3: number
}

export interface HeatingData {
    gasUsage_m3: number,
    electricityUsageHeatPump_kWh: number,
    energyCVKetel_GJ: number,
    energyHeatPump_GJ: number,
    cvKetelPumpLoss_percent: number,
    heatPumpPumpLoss_percent: number
}

export interface HeatingResult {
    gasUsageHeating_m3: number,
    physicalFromGasInstallation_GJ: number,
    measuredEnergyCVKetelAdjustedWithPumpLoss_GJ: number,
    calculatedGainEnergyCVKetel_percent: number,
    physicalFromkWhHeatPumpInstallation_GJ: number,
    measuredEnergyHeatPump_GJ: number,
    measuredEnergyHeatPumpAdjustedWithPumpLoss_GJ: number,
    calculatedGainHeatPump_percent: number,
    energyInstallation_GJ: number,
    totalMeasuredEnergyAdjustedWithPumpLoss_GJ: number,
    calculatedGainInstallation_percent: number
}

export interface FinancialAndEnvironmentalData {
    energyCostsGas_euros: number,
    energyCostsElectricity_euros: number
}

export interface FinancialAndEnvironmentalResult {
    energyCostsTotal_euros: number,
    fictionalGasUsageHeating_m3: number,
    fictionalGasOnly_euros: number,
    saving_euros: number,
    cO2_kg: number,
    cO2Fictional_kg: number,
    cO2Reduction_kg: number
}

export interface ModNodeData {
    warmWaterData: WarmWaterData,
    warmWaterResult: WarmWaterResult,
    cookingData: CookingData,
    cookingResult: CookingResult,
    heatingData: HeatingData,
    heatingResult: HeatingResult,
    financialAndEnvironmentalData: FinancialAndEnvironmentalData,
    financialAndEnvironmentalResult: FinancialAndEnvironmentalResult,
    globalData: GlobalData,
    systemType: number,
    startDate: Date,
    endDate: Date
}

export interface ModNode {
    preloaded: boolean,
    updatedValue: boolean,
    nodeInfo: NodeInfo
    nodeData: ModNodeData,
    dataStartDate: Date,
    dataEndDate: Date
}

export interface DetailNode {
    nodeInfo: NodeInfo,
    meters: Array<MeterWithMeterUnits>,
    shownUnits: Array<ShownUnit>,
    dataStartDate: Date,
    dataEndDate: Date,
    preloaded: boolean,
    dataLoaded: boolean
}

export interface NodeDataList {
    calcDataFields: Array<CalcDataField>,
    nodeDatas: Array<NodeData>,
    dataStartDate: Date,
    dataEndDate: Date,
    preloaded: boolean
}

export interface Default {
    userId: string,
    nodes: Array<NodeInfo>,
    detailNode: NodeInfoItem,
    comparedNodes: Array<NodeInfoItem>,
    nodeUserRelations: any,
    updatedMeters: any,
    preloaded: boolean
}

export interface EMRAPageInfo {
    detailNode: NodeInfo,
    address: string,
    alias: string,
    comment: string,
    relatedUsers: any[],
    systemType: number,
    countries: any[],
    preloaded: boolean
}

export type ShownUnitWithShouldUpdateFlag = {
    shownUnit: ShownUnit,
    shouldUpdate: boolean
}

export type NodeVariableUpdate = {
    nodeId: string,
    nodeVariableName: string,
    newValue: number
}

export type GlobalVariableUpdate = {
    globalVariableName: string,
    newValue: number
}


/* addEmra // AddEMRA page */
export type EMRAConfigurationPreloadData = {
    preloaded: boolean,
    preloadData: any
}

export type ConfigurationCardData = {
    title: string,
    headers: string[],
    items: any[]
}


/* meters // Meters page */
export type MeterPagePreload = {
    preloaded: boolean,
    preloadData: any,
    selectedNode: NodeInfo
}
