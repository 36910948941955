
import { NodeInfo } from '@/types/state'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class DefaultHome extends Vue {
    @Prop()
    node!: NodeInfo

    @Prop()
    outOfUse!: boolean

    @Prop()
    updated!: boolean

    toggleComparison(): void {
        this.$store.commit('default/TOGGLE_COMPARISON', { ...this.node })
    }

    get compare(): boolean {
        return this.$store.getters['default/ComparedNodes']?.filter((n: NodeInfo) => n.id == this.node.id)[0] != null
    }

    get title(): string {
        return this.node.alias
    }
    get statusColor(): string {
        return this.node.isOnline ? "green" : "red"
    }

    get statusText(): string {
        return this.node.isOnline ? "Online" : "Offline"
    }
    
    get formattedDate(): string {
        const date = new Date(this.node?.updatedAt)
         return (this.node?.updatedAt) ?
            date.getDate().toString().padStart(2, '0') + "/" +
            (date.getMonth() + 1).toString().padStart(2, '0') + "/" +
            date.getFullYear() : '-'
    }

    get tooltipMessage(): string {
        const date = new Date(this.node?.updatedAt)
        const stringDate = this.formattedDate
        const stringTime = date.getHours().toString().padStart(2, '0') + ":" + 
            date.getMinutes().toString().padStart(2, '0')

        return (this.node?.updatedAt) ? 
            `Laatste data opgehaald op ${ stringDate } om ${ stringTime } uur` :
            `Er is nog geen data opgehaald.`
    }
}
