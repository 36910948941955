
import { Component, Vue } from 'vue-property-decorator'

import InfoCard from '@/components/StationInfoCard.vue'
import { NodeInfo, NodeInfoFunctions } from '@/types/state'


import store from '@/store'
import { UserRole } from '@/enums'


@Component({
    components: {
      InfoCard
    }
})
export default class DefaultHome extends Vue {
  searchValue = ''
  aliasFilter = 1
  filterOnline = 0
  filterChanges = 0
  filterInUse = 0

  resetFilters() {
    this.filterOnline = 0
    this.filterChanges = 0
    this.filterInUse = 0
  }

  containsTerm(target: string, term: string): boolean {
    return target.toLowerCase().includes(term.toLowerCase())
  }

  filterNodesByAlias(nodes: Array<NodeInfo>): Array<NodeInfo> {
    return (this.aliasFilter == 0) ? 
      nodes :
      (this.aliasFilter == 1) ? 
        NodeInfoFunctions.sortDesc(nodes) :
        NodeInfoFunctions.sortAsc(nodes)
  }

  addToComparison(node: NodeInfo): void {
    this.$store.commit('default/ADD_TO_COMPARISON', { ...node })
  }

  async setDetailNode(node: NodeInfo): Promise<void> {
    await this.$store.commit('detailNode/SET_NODE_INFO', { ...node })
    this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...node} )
    this.$router.push({ name: this.userRole == UserRole.User ? "DataPage" : (this.userRole != UserRole.None ? "ModEMRAPage" : "UserHome") })
  }
  
  get filteredNodes(): Array<NodeInfo> {
    const filtered = this.nodes.filter(n => this.containsTerm(n.alias, this.searchValue)
      && (this.filterInUse === 0 ? true : (this.filterInUse === 1))
      && (this.filterOnline === 0 ? true : (this.filterOnline === 1 ? n.isOnline : !n.isOnline))
      && (this.filterChanges === 0 ? true : (this.filterChanges === 1 ? this.updatedNodes.includes(n.id) : !this.updatedNodes.includes(n.id))))
    
    return this.filterNodesByAlias(filtered)
  }

  get filteredNodesOutOfUse(): Array<NodeInfo> {
    const filtered = this.nodesOutOfUse.filter(n => this.containsTerm(n.alias, this.searchValue)
      && (this.filterInUse === 0 ? true : (this.filterInUse === 2))
      && (this.filterOnline === 0 ? true : (this.filterOnline === 2))
      && (this.filterChanges === 0 ? true : (this.filterChanges === 1 ? this.updatedNodes.includes(n.id) : !this.updatedNodes.includes(n.id))))

    return this.filterNodesByAlias(filtered)
  }

  get aliasFilterIcon(): string {
    switch(this.aliasFilter) {
      case 0:   return 'mdi-sort-alphabetical-variant'
      case 1:   return 'mdi-sort-alphabetical-ascending'
      default:  return 'mdi-sort-alphabetical-descending'
    }
  }

  get nodes(): Array<NodeInfo> {
    return this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) => !n.outOfUse)
  }

  get nodesOnline(): number {
    return this.nodes.filter((n: NodeInfo) => n.isOnline).length
  }

  get nodesOutOfUse(): Array<NodeInfo> {
    return this.isModOrAdmin
      ? this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) => n.outOfUse)
      : []
  }

  get updatedNodes(): Array<any> {
      return this.$store.getters["default/UpdatedMeters"]?.map((um: any) => um.nodeId)
  }

  get isLoaded(): boolean {
    return this.$store.getters['default/IsPreloaded']
  }

  get userRole(): string {
    return this.$store.getters['identity/role']
  }

  get isModOrAdmin(): boolean {
    return this.userRole == 'Moderator' || this.userRole == 'Admin'
  }

  mounted(): void {
    this.$store.commit('emraPageInfo/SET_NODE_INFO', null)
  }

  created(): void {
    this.$store.commit('detailNode/SET_PRELOADED', false)
  }
}
